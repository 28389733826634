import React, { useContext, useEffect, useState } from "react";
import Panel from "../Panel";
import { authContext } from "../../containers/AuthProvider";
import { Link, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Comment,
  CommentAuthor,
  CommentContent,
  CommentGroup,
  CommentMetadata,
  CommentText,
  Dimmer,
  Grid,
  GridColumn,
  Icon,
  Label,
  Loader,
  Message,
  Segment,
  Form,
  FormTextArea,
} from "semantic-ui-react";
import { getDiagnostics } from "../../libs/requests/getDiagnostics";
import { themeContext } from "../../containers/ThemeProvider";

const Fault = ({ fault, isOpen, onSubmitUpdate }) => {
  const { assets } = useContext(themeContext);
  const [updateText, setUpdateText] = useState("");

  const handleTextChange = (e) => {
    setUpdateText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (updateText.trim()) {
      onSubmitUpdate(updateText, fault); // Passing the fault object to onSubmitUpdate
      setUpdateText("");
    } else {
      alert("Please enter a valid update.");
    }
  };

  return (
    <>
      <Grid columns={2} divided>
        <GridColumn>
          <strong>Reference:</strong> {fault.faultDetails.faultReference}
        </GridColumn>
        <GridColumn>
          <strong>Status:</strong> {fault.faultDetails.status || "N/A"}
        </GridColumn>
        <GridColumn>
          <strong>Type:</strong> {fault.faultDetails.type}
        </GridColumn>
        <GridColumn>
          <strong>Raised Date:</strong>{" "}
          {new Date(fault.faultDetails.raisedDate).toLocaleDateString()}
        </GridColumn>
        <GridColumn>
          <strong>Description:</strong> {fault.faultDetails.description}
        </GridColumn>
        <GridColumn>
          <strong>Intermittency:</strong>{" "}
          {fault.faultDetails.isIntermittent ? "Yes" : "No"}
        </GridColumn>
        {fault.faultDetails.isIntermittent && (
          <>
            <GridColumn>
              <strong>Pattern:</strong>{" "}
              {fault.faultDetails.intermittencyDetails.intermittencyPattern}
            </GridColumn>
            <GridColumn>
              <strong>Occurrences Per Day:</strong>{" "}
              {fault.faultDetails.intermittencyDetails.occurrencesPerDay}
            </GridColumn>
          </>
        )}
        <GridColumn>
          <strong>Last Modified:</strong>{" "}
          {new Date(fault.faultDetails.lastModifiedDate).toLocaleDateString()}
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn>
          <CommentGroup>
            <h3>Comments</h3>
            {fault.updateHistory.map((update, index) => (
              <Comment key={index}>
                <CommentContent>
                  <CommentAuthor as="strong">{update.createdBy}</CommentAuthor>
                  <CommentMetadata>
                    <span>
                      {new Date(update.lastModifiedDate).toLocaleDateString()}
                    </span>
                  </CommentMetadata>
                  <CommentText>{update.update}</CommentText>
                </CommentContent>
              </Comment>
            ))}
          </CommentGroup>
        </GridColumn>
      </Grid>
      {isOpen && (
        <Grid>
          <GridColumn>
            <Form reply onSubmit={handleSubmit}>
              <FormTextArea
                value={updateText}
                onChange={handleTextChange}
                placeholder="Write your update here..."
              />
              <Button
                content="Reply"
                labelPosition="left"
                icon="edit"
                primary
                style={{ backgroundColor: assets?.headerColour }}
              />
            </Form>
          </GridColumn>
        </Grid>
      )}
    </>
  );
};

export default function Faults() {
  const [isLoading, setIsLoading] = useState(false);
  const [openFault, setOpenFault] = useState(null);
  const [closedFaults, setClosedFaults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [postStatus, setPostStatus] = useState("");
  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    async function fetchFaults() {
      setIsLoading(true);
      try {
        const response = await getDiagnostics(reference, ["Faults"]);

        if (response.faults && response.faults.openFault) {
          setOpenFault(response.faults.openFault || []);
        }

        if (response.faults && response.faults.closedFaults) {
          setClosedFaults(response.faults.closedFaults || []);
        }
      } catch (error) {
        console.error("Error fetching faults:", error);
      } finally {
        setIsLoading(false);
      }
    }

    if (permissions.servicesAccess) {
      fetchFaults();
    }
  }, [reference, permissions.servicesAccess]);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    setActiveIndex(activeIndex === index ? -1 : index);
  }

  const handleSubmitUpdate = async (updateText, fault) => {
    const FaultReference = fault.faultDetails.faultReference; // Use fault's faultReference

    const updateRequest = {
      ZenReference: reference, // Service ZenReference
      RequestPairs: {
        UpdateFaults: "UpdateFaults",
      },
      Update: {
        Service: { ZenReference: reference }, // Use the same ZenReference for the service
        Contact: { Email: "user@example.com" }, // Replace with actual user's email
        UpdateDetails: { Update: updateText },
      },
      FaultReference: FaultReference, // Add FaultReference from the fault object
    };

    try {
      const response = await getDiagnostics(
        reference,
        ["UpdateFaults"],
        "POST",
        updateRequest
      );

      if (response?.updateFaultsStatus === "OK") {
        setPostStatus("Update successfully posted.");
      } else {
        setPostStatus("An error occurred while posting the update.");
      }
    } catch (error) {
      setPostStatus("An error occurred while posting the update.");
      console.error("Error posting update:", error);
    }
  };


  if (!permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    );
  }

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      {openFault && (
        <Panel header={"Open Fault"}>
          <Fault
            fault={openFault}
            isOpen={true}
            onSubmitUpdate={handleSubmitUpdate}
          />
        </Panel>
      )}

      {postStatus && (
        <Message
          success={postStatus === "Update successfully posted."}
          error={postStatus !== "Update successfully posted."}
        >
          <p>{postStatus}</p>
        </Message>
      )}
      {closedFaults.length > 0 && (
        <Panel header={"Recently Closed Faults"}>
          <Accordion fluid styled>
            {closedFaults.map((fault, idx) => (
              <React.Fragment key={idx}>
                <AccordionTitle
                  active={activeIndex === idx}
                  index={idx}
                  onClick={handleClick}
                >
                  <Label ribbon color="red">
                    Closed
                  </Label>
                  <Icon name="dropdown" />
                  {fault.faultDetails.faultReference} (
                  {new Date(fault.faultDetails.raisedDate).toLocaleDateString()}
                  )
                </AccordionTitle>
                <AccordionContent active={activeIndex === idx}>
                  <Fault
                    fault={fault}
                    isOpen={false}
                    onSubmitUpdate={handleSubmitUpdate}
                  />
                </AccordionContent>
              </React.Fragment>
            ))}
          </Accordion>
        </Panel>
      )}
    </>
  );
}
