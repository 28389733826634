import React, { useContext, useEffect, useState } from "react";
import Panel from "./Panel";
import { authContext } from "../containers/AuthProvider";
import { useParams } from "react-router-dom";
import {
  Button,
  Dimmer,
  Grid,
  GridColumn,
  Header,
  Label,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { searchServices } from "../libs/requests/serviceSearch";
import { Link } from "react-router-dom";
import RadacctGraph from "./Diagnostics/RadacctGraph";
import { getDiagnostics } from "../libs/requests/getDiagnostics";
import { themeContext } from '../containers/ThemeProvider';

export default function ViewConnectionStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const { reference } = useParams();
  const { permissions } = useContext(authContext);
  const [connectionDetails, setConnectionDetails] = useState({});
  const [dropsOverTime, setDropsOverTime] = useState({});
  const [service, setService] = useState({});

  const { assets } = useContext(themeContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          setIsLoading(true);

          const startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          const formattedStartDate = startDate.toISOString().split(".")[0];

          const endDate = new Date().toISOString().split(".")[0];

          const queryString = `startdate=${encodeURIComponent(
            formattedStartDate
          )}&enddate=${encodeURIComponent(endDate)}&maxconnections=5000`;

          const response = await getDiagnostics(
            reference,
            ["ConnectionStatus", "DropsOverTime"],
            "POST",
            {},
            queryString
          );

          setService((await searchServices(reference))[0]);
          setConnectionDetails(response?.zenConnectionResponse || {});

          setDropsOverTime(response?.dropsOverTime || {});
          debugger;

          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    })();
  }, []);

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    );
  }

  return (
    <Segment>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      {service?.serviceStatus === "Active" && (
        <>
          <Panel>
            <Grid columns={4}>
              <GridColumn>
                <strong>CLI</strong>
                <br />
                {service?.phoneNumber}
              </GridColumn>
              <GridColumn>
                <strong>Customer ref</strong>
                <br />
                {service?.customerReference}
              </GridColumn>
              <GridColumn>
                <strong>Postcode</strong>
                <br />
                {service?.postCode}
              </GridColumn>
              {service?.serviceStatus === "Active" && (
                <GridColumn>
                  <Link to={`/Diagnostics/${service?.zenReference}`}>
                    <Button
                      content="Diagnose Fault"
                      icon="wrench"
                      labelPosition="right"
                      primary
                      style={{ backgroundColor: assets?.headerColour }}
                    />
                  </Link>
                </GridColumn>
              )}
            </Grid>
          </Panel>
          <Panel header={"Connection Status"}>
            <Grid columns={4}>
              <GridColumn>
                <strong>Connection Status</strong>
                <br />
                <Label color="green">
                  {connectionDetails?.connectionStatus?.Connected || ""}
                </Label>
              </GridColumn>
              <GridColumn>
                <strong>Uptime</strong>
                <br />
                {connectionDetails?.model?.connectionStatus?.uptime || ""}
              </GridColumn>
              <GridColumn>
                <strong>Expected username</strong>
                <br />
                {connectionDetails?.model?.credentials?.username || ""}
              </GridColumn>
              <GridColumn>
                <strong>Expected password</strong>
                <br />
                {connectionDetails?.model?.credentials?.password || ""}
              </GridColumn>
            </Grid>
          </Panel>

          <Panel header={"Session Information"}>
            <Segment>
              <Header>Radius Session Status</Header>
              {dropsOverTime.model && (
                <RadacctGraph
                  data={dropsOverTime.model}
                  width={870}
                  height={580}
                />
              )}
            </Segment>
          </Panel>
        </>
      )}
    </Segment>
  );
}
