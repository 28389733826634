import React from 'react';

const footerStyle = {
  position: 'relative', // Changed from 'fixed' to 'relative'
  width: '100%',
  textAlign: 'center',
  padding: '10px', // Reduced padding
  backgroundColor: '#f1f1f1', // Subtle background color
  fontSize: '12px', // Smaller font size
  color: '#666', // Lighter text color
  marginTop: 'auto' // Ensures footer is pushed to the bottom if there's not enough content
};

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>
         This portal is licensed software. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
