import React, { useContext, useEffect, useState } from 'react';
import Panel from './Panel';
import { authContext } from '../containers/AuthProvider';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Grid, GridColumn, Label, Loader, Segment } from 'semantic-ui-react';
import Faults from './Diagnostics/Faults';
import ServiceTests from './Diagnostics/ServiceTests';
import RaiseFault from './Diagnostics/RaiseFault';
import './ViewDiagnostics.css';
import { searchServices } from '../libs/requests/serviceSearch';
import ServiceTestsSOGEA from './Diagnostics/ServiceTestsSOGEA';
import ServiceTestsFibre from './Diagnostics/ServiceTestsFibre';
import ServiceTestsCopper from './Diagnostics/ServiceTestsCopper';

export default function ViewDiagnostics() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState({});
  const [showRaiseFault, setShowRaiseFault] = useState(false);
  const [testId, setTestId] = useState(null);
  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  const handleTestIdReceived = (testId) => {
    setTestId(testId);
  };

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          const response = await searchServices(reference);
          setService(response[0]);
        }
      } catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, [reference, permissions.servicesAccess]);

  return (
    <>
      <Segment style={{ minWidth: '70em' }}>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {permissions.servicesAccess && (
          <>
            {showRaiseFault && <RaiseFault onCancel={() => setShowRaiseFault(false)} testId={testId} />}

            <Panel header={'Connection Details'} clearing>
              <div>
                <Button
                  icon="wrench"
                  content="Raise Fault"
                  color="red"
                  labelPosition="left"
                  floated="right"
                  onClick={() => setShowRaiseFault(true)}
                  disabled={showRaiseFault}
                  style={{ marginTop: '1.1em' }}
                />
                <Grid columns={3}>
                  <GridColumn>
                    <strong>CLI</strong>
                    <br />
                    {service?.cli}
                  </GridColumn>
                  <GridColumn>
                    <strong>Customer ref</strong>
                    <br />
                    {service?.customerReference}
                  </GridColumn>
                  <GridColumn>
                    <strong>Postcode</strong>
                    <br />
                    {service?.postCode}
                  </GridColumn>
                </Grid>
              </div>
            </Panel>

            {service?.productCode?.toUpperCase()?.startsWith('FTTP') && (
              <ServiceTests
                reference={service?.zenReference}
                onTestIdReceived={handleTestIdReceived} 
              />
            )}
            {service?.productCode?.toUpperCase()?.startsWith('SOGEA') && (
              <ServiceTestsSOGEA reference={service?.zenReference} 
              onTestIdReceived={handleTestIdReceived} 
              />
            )}
            {(service?.productCode?.toUpperCase()?.startsWith('FTTC') ||
              service?.productCode?.toUpperCase()?.startsWith('GFAST')) && (
              <ServiceTestsFibre reference={service?.zenReference} 
              onTestIdReceived={handleTestIdReceived} 
              />
            )}
            {service?.productCode?.toUpperCase()?.startsWith('ADSL') && (
              <ServiceTestsCopper reference={service?.zenReference} 
              onTestIdReceived={handleTestIdReceived} 
              />
            )}
          </>
        )}
      </Segment>
    </>
  );
}
