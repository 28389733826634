import React, { useContext, useEffect, useState, useRef } from 'react';
import Panel from './Panel';
import { Dimmer, Input, Loader, Message, Segment, Table, Pagination, Icon, Dropdown } from 'semantic-ui-react';
import { searchOrders } from '../libs/requests/orderSearch';
import { Link } from 'react-router-dom';
import { authContext } from '../containers/AuthProvider';
import moment from 'moment-timezone';
import { Alert } from 'react-bootstrap';
import updatePartnerAsset from '../libs/requests/updatePartnerAsset';
import { getPartners } from '../libs/requests/getPartnerDetails';
import { useLoading, useOrders } from './customHooks';
import { getSupplier } from '../libs/sessionVariable';

let timeout = null;

export default function SearchOrders() {
  const [isLoading, setIsLoading] = useLoading(true);
  const [isError, setIsError] = useState(false);
  const [orders, setOrders] = useOrders([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState({
    index: 0,
    size: 10,
    count: 1,
    data: []
  });
  const [info, setInfo] = useState({});
  const { permissions } = useContext(authContext);
  const abortControllerRef = useRef(null);
  const [isHighttide, setIsHighttide] = useState(false);
  const highTidePartners = [
    {
      "Tenant": "Faelix",
      "Partner": "OneHelix",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "E-volve Solutions",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "IT4 Automation",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "Community Waves",
      "Realms": [""]
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await searchOrders('');
        setOrders(result.orders || []);
        setFilteredOrders(result.orders || []);

        if (permissions.admin) {
          const supplier = await getSupplier();
          setIsHighttide(supplier === 'Hightide');

          if (supplier === 'Hightide') {
            setPartners(highTidePartners);  // Use the hardcoded list if supplier is Hightide
          } else {
            const partnerList = await getPartners();  // Fetch partners otherwise
            
            // Normalize the partners list into objects matching the Hightide format
            const normalizedPartners = partnerList.map(partner => ({
              Tenant: '',         // Assume Tenant is empty or set it to something appropriate
              Partner: partner,   // Use the string from the array as the Partner
              Realms: ['']        // Keep Realms as an empty array for consistency
            }));

            setPartners(normalizedPartners);
          }
        }

        setIsLoading(false);
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [permissions]);

  useEffect(() => {
    buildPage(1);
  }, [filteredOrders]);

  const buildPage = (pageIndex) => {
    if (!Array.isArray(filteredOrders)) {
      console.error("filteredOrders is not an array", filteredOrders);
      return;
    }

    const { size } = page;
    const count = filteredOrders.length;
    const noPages = count % size === 0 ? count / size : Math.trunc(count / size) + 1;
    const startIdx = (pageIndex - 1) * size;

    setPage({
      ...page,
      index: pageIndex,
      count: noPages,
      data: filteredOrders.slice(startIdx, startIdx + size),
      isFirst: pageIndex === 1,
      isLast: pageIndex === noPages
    });
  };

  const handleSearchChange = (evt) => {
    const searchTerm = evt.target.value;
    clearTimeout(timeout);
  
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  
    // Re-initialize the AbortController correctly
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
  
    // debounce input to reduce the number of requests sent
    timeout = setTimeout(async () => {
      setIsLoading(true);
      try {
        const result = await searchOrders(searchTerm, { signal });
        if (!signal.aborted) {
          setFilteredOrders(result.orders || []);
        }
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error('Error searching orders:', err);
          setIsError(true);
        }
      }
      setIsLoading(false);
    }, 300);
  };
  

  const handlePaginationChange = (e, { activePage }) => {
    buildPage(activePage);
  };

  const assign = (reference, partner) => {
    (async () => {
      setInfo({
        type: 'info',
        text: `Assigning ${reference} to ${partner}...`
      });
  
      partner = isHighttide ? `Hightide|${partner}` : partner;
  
      // Determine the supplier based on the reference prefix
      let supplier;
      if (reference.toUpperCase().startsWith('Z')) {
        supplier = 'ZEN';
      } else if (reference.toUpperCase().startsWith('ORD')) {
        supplier = 'GIGANET';
      } else {
        // Handle the case where the reference doesn't match expected patterns
        setInfo({
          type: 'warning',
          text: `Invalid reference format for ${reference}.`
        });
        return;
      }
  
      const result = await updatePartnerAsset({
        reference,
        partner,
        supplier
      });
  
      setInfo({
        type: result ? 'success' : 'warning',
        text: result ? `Assigned ${reference} to ${partner}` : `Failed to assign ${reference} to ${partner}`
      });
    })();
  };  

  if (!isLoading && (!permissions.orderAccess || !permissions || (permissions && !permissions.hasOwnProperty('admin')))) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    );
  }

  if (isError) {
    return (
      <Panel>
        <Message error header="There was an error searching for orders, please check and try again" />
      </Panel>
    );
  }

  const { data, index, isFirst, isLast, count } = page;

  return (
    <>
      <Panel header={'Orders'}>

        {!!info.text && (
          <Alert bsStyle={info.type}>{info.text}</Alert>
        )}

        <Input
          data-testid='ordersSearchPanel'
          icon='search'
          placeholder='Search Orders...'
          loading={isLoading}
          size="large"
          onChange={handleSearchChange}
          style={{ width: '100%' }}
        />

        {!isLoading && !filteredOrders.length && <Message info header='No orders found' />}

        <Segment style={{ minHeight: '100px', width: '100%' }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Reference</Table.HeaderCell>
                <Table.HeaderCell>Placed Date</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Customer Reference</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>CLI</Table.HeaderCell>
                {permissions.admin && <Table.HeaderCell>Actions</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>

            {!!filteredOrders.length && (
              <>
                <Table.Body>
                  {data.map(order => (
                    <Table.Row key={order.reference}>
                      <Table.Cell>
                        <Link to={`/Order/${order.reference}`}>
                          {order.reference}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{moment(order.orderDate).format('DD-MM-YY')}</Table.Cell>
                      <Table.Cell>{order.provisionType}</Table.Cell>
                      <Table.Cell>{order.customerReference}</Table.Cell>
                      <Table.Cell>{order.productDescription}</Table.Cell>
                      <Table.Cell>{order.phoneNumber}</Table.Cell>
                     
                      {(permissions.admin) && (
                        <Table.Cell>
                          <Dropdown text='Assign'>
                            <Dropdown.Menu>
                              {partners.map(o => (
                                <Dropdown.Item 
                                  text={o.Partner} 
                                  key={o.Partner} 
                                  onClick={() => assign(order.reference, o.Partner)} 
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            )}
          </Table>

          {!!filteredOrders.length && (
            <Pagination
              activePage={index}
              onPageChange={handlePaginationChange}
              totalPages={count}
              firstItem={null}
              lastItem={null}
              prevItem={{ content: <Icon name='angle left' disabled={isFirst} />, icon: true }}
              nextItem={{ content: <Icon name='angle right' disabled={isLast} />, icon: true }}
            />
          )}
        </Segment>
      </Panel>
    </>
  );
}
