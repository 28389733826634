import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export async function getDiagnostics(reference, requestTypes, method = "POST", body = null, queryString = "") {
  const requestPairs = requestTypes.reduce((pairs, type) => {
    pairs[type] = type;
    return pairs;
  }, {});

  const bodyContent = {
    ZenReference: reference,
    RequestPairs: requestPairs,
    ...(body && { ...body }),
  };

  const url = `${BASE_URL}/zen-assurance${queryString ? `?${queryString}` : ""}`;

  try {
    const results = await makeRequest(url, { method, body: JSON.stringify(bodyContent) });
    
    const { message, modelState, errors, errorMessage } = results || {};

    if (message === "The request is invalid.") {
      throw modelState;
    }

    if (message) {
      throw message; 
    }

    if (errors && errors.length) {
      throw errors; 
    }

    if (errorMessage && results.zenReference) {
      throw `${results.zenReference} - Error occurred while retrieving diagnostics.`;
    }

    if (errorMessage) {
      throw "An error occurred on the server, please contact support for assistance.";
    }

    if(results?.updateFaultsStatus === 'BadRequest'){
      throw "Failed tp update fault.";
    }

    return results;
  } catch (error) {
    console.error(`Error fetching diagnostics for reference ${reference} with requestType ${requestPairs}:`, error.message);
    throw error;
  }
}
