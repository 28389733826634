import * as d3 from 'd3';

// Helper constants
const classMap = {
  conn: 'radacct_conn',
  reco: 'radacct_reco',
  diss: 'radacct_diss',
  unkn: 'radacct_default'
};

const margin = 50;
const fontSize = 10;
const tickLen = 5;

// Utility functions for rendering the graph
export const renderGraph = (node, width, height, payload, data) => {
  const heightOfItem = parseInt((height - margin * 2) / (24 * 4));
  const vertStart = 10;
  const vertEnd = vertStart + heightOfItem * (24 * 4);
  let widthOfItem = (width - margin * 2) / payload.length;

  const radacctGraph = createGraph(node, width, height);

  renderGraphOutline(radacctGraph, width, height, widthOfItem, payload.length);

  payload.forEach((day, idx) => {
    renderDay(radacctGraph, day, idx, widthOfItem, heightOfItem, vertStart, vertEnd);
  });

  for (let i = 0; i <= 96; i++) {
    renderYAxis(radacctGraph, i, vertStart, heightOfItem);
  }

  return (
    <div className="graph" style={{ width: width, height: height }}>
      {node.toReact()}
    </div>
  );
}

const createGraph = (node, width, height) => {
  return d3.select(node)
    .append('svg')
    .attr('width', width)
    .attr('height', height);
}

const renderGraphOutline = (radacctGraph, width, height, widthOfItem, payloadLength) => {
  // Background rectangle
  radacctGraph.append('rect')
    .attr('x', 1)
    .attr('y', 1)
    .attr('width', width - 2)
    .attr('height', height - 2)
    .style('fill', 'rgb(255, 255, 255)');

  // Graph outline lines
  renderLineFromPoint(radacctGraph, { x: margin, y: 10 }, { x: margin, y: height - 40 });
  const right = margin + widthOfItem * payloadLength;
  renderLineFromPoint(radacctGraph, { x: right, y: 10 }, { x: right, y: height - 40 });

  // Axis label
  radacctGraph
    .append('text')
    .attr('text-anchor', 'start')
    .attr('class', 'raddact_legend')
    .attr('transform', `translate(${fontSize}, 275)rotate(270)`)
    .text('Time');
}

const renderLineFromPoint = (radacctGraph, start, end) => {
  radacctGraph.append('line')
    .attr('x1', start.x)
    .attr('y1', start.y)
    .attr('x2', end.x)
    .attr('y2', end.y)
    .style('stroke', 'rgb(0,0,0)')
    .style('stroke-width', 0.5);
}

const renderDay = (radacctGraph, day, idx, widthOfItem, heightOfItem, vertStart, vertEnd) => {
  const { data } = day;
  data.sort((a, b) => b.id - a.id);

  const x = margin + idx * widthOfItem;

  // Day label
  radacctGraph
    .append('text')
    .attr('font-size', fontSize)
    .attr('font-family', 'arial')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('transform', `translate(${x + (widthOfItem * 0.75) / 2},${vertEnd + tickLen + 25})rotate(300)`)
    .text(day.date);

  // Day vertical line
  renderLineFromPoint(radacctGraph, { x, y: vertEnd }, { x, y: vertEnd + tickLen });

  // Render each data point for the day
  let y = vertStart;
  data.forEach(item => {
    renderDataRect(radacctGraph, { x, y }, item, widthOfItem, heightOfItem);
    y += heightOfItem;
  });
}

const renderDataRect = (radacctGraph, point, status, widthOfItem, heightOfItem) => {
  radacctGraph
    .append('rect')
    .attr('x', point.x)
    .attr('y', point.y)
    .attr('width', widthOfItem - 1)
    .attr('height', heightOfItem - 1)
    .attr('class', classMap[status.v] || 'radacct_default');
}

const renderYAxis = (radacctGraph, i, vertStart, heightOfItem) => {
  let x1 = margin;
  const y = vertStart + i * heightOfItem;

  if (i % 4 === 0) {
    x1 -= tickLen;

    const label = 24 - i / 4;

    renderAxisLabelFromPoint(radacctGraph, { x: x1 - tickLen, y }, label);
    renderLineFromPoint(radacctGraph, { x: x1, y }, { x: x1 + tickLen, y });
  }
}

const renderAxisLabelFromPoint = (radacctGraph, point, text) => {
  radacctGraph.append('text')
    .attr('x', point.x)
    .attr('y', point.y)
    .attr('font-size', fontSize)
    .attr('font-family', 'arial')
    .attr('text-anchor', 'end')
    .text(text);
}