import React, { useContext, useEffect, useState } from "react";
import Panel from "./Panel";
import {
  Button,
  Dimmer,
  Divider,
  Grid,
  GridColumn,
  Loader,
  Message,
  Segment,
  Label,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { authContext } from "../containers/AuthProvider";
import { searchServices } from "../libs/requests/serviceSearch";
import Faults from "./Diagnostics/Faults";
import { getDiagnostics } from "../libs/requests/getDiagnostics";

const fields = [
  { key: "serviceId", label: "ServiceId" },
  { key: "phoneNumber", label: "CLI" },
  { key: "customerReference", label: "Customer Ref" },
  { key: "serviceStatus", label: "Service Status" },
  { key: "startDate", label: "Service Start Date" },
  { divider: true },
  { key: "productDescription", label: "Product" },
  { key: "careLevel", label: "Care Level" },
  { key: "trafficWeighting", label: "Traffic Weighting" },
  { key: "network", label: "Network" },
  { key: "upstreamSpeed", label: "Upstream Speed" },
];

const addressFields = [
  { key: "organisationName", label: "Organisation" },
  { key: "subPremiseName", label: "Sub Premise" },
  { key: "premiseName", label: "Premise" },
  { key: "thoroughfareNumber", label: "House Number" },
  { key: "thoroughfare", label: "Street" },
  { key: "dependentThoroughfare", label: "Dependent Thoroughfare" },
  { key: "postTown", label: "Town" },
  { key: "postCode", label: "Post Code" },
];

const GridItem = ({ field, value }) => {
  const { divider, label } = field;

  const displayValue = value && field.format ? field.format(value) : value;

  if (divider)
    return (
      <GridColumn width={16}>
        <Divider />
      </GridColumn>
    );

  return (
    <GridColumn key={label}>
      <Grid columns={2}>
        <GridColumn>
          <strong>{label}</strong>
        </GridColumn>
        <GridColumn>{displayValue}</GridColumn>
      </Grid>
    </GridColumn>
  );
};

export default function ViewService() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [service, setService] = useState({});

  const { reference } = useParams();
  const { permissions } = useContext(authContext);
  const [serviceStatus, setServiceStatus] = useState("");
  const [connectionDetails, setConnectionDetails] = useState({});
  const [exchangeStatus, setExchangeStatus] = useState("");
  const [broadbandConnection, setBroadbandConnection] = useState({});
  const [requestPairs, setRequestPairs] = useState([
    "ConnectionStatus",
    "MajorServiceOutage",
    "BroadbandConnection",
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          setIsLoading(true);
          setService((await searchServices(reference))[0]);
          setServiceStatus(
            service.serviceStatus == "CeasedWithSupplier" ||
              service.serviceStatus == "ActiveCeasePending"
              ? service.serviceStatus
              : "Active"
          );
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          const response = await getDiagnostics(reference, requestPairs);
          setConnectionDetails(response?.zenConnectionResponse || null);

          setExchangeStatus(
            response?.majorServiceOutage?.Results?.length > 0
              ? "Outage detected"
              : "No known outages"
          );
          setBroadbandConnection(response?.broadbandConnection || {});
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    );
  }

  const header = (
    <>
      <span>{`Service Reference: ${reference}`}</span>
      <strong style={{ float: "right" }}>
        {service ? service.status : ""}
      </strong>
    </>
  );

  return (
    <>
      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Panel header={"Service Details"} {...(<Link>Modify</Link>)}>
          {service.serviceStatus === "Active" &&
            (service?.productCode?.toUpperCase()?.startsWith("FTTP") ||
              service?.productCode?.toUpperCase()?.startsWith("SOGEA") ||
              service?.productCode?.toUpperCase()?.startsWith("GFAST") ||
              service?.productCode?.toUpperCase()?.startsWith("FTTC") ||
              service?.productCode?.toUpperCase()?.startsWith("ADSL")) && (
              <Link to={`/Diagnostics/${reference}`}>
                <Button
                  primary
                  className="button-primary"
                  content="Diagnostics"
                  icon="wrench"
                  labelPosition="left"
                  floated="right"
                />
              </Link>
            )}

          <Grid columns={4} style={{ padding: "20px 0" }}>
            <span>
              <Label
                style={{
                  color: "black",
                  backgroundColor:
                    serviceStatus === "CeasedWithSupplier" ||
                    serviceStatus === "ActiveCeasePending"
                      ? "grey"
                      : "green",
                  fontWeight: "bold",
                }}
              >
                Service Status: {serviceStatus}
              </Label>
            </span>
            {connectionDetails &&
              !permissions.isL2 &&
              serviceStatus === "Active" && (
                <Link
                  to={{
                    pathname: `/Diagnostics/Connection/${reference}`,
                  }}
                >
                  <Label
                    style={{
                      color: "black",
                      backgroundColor:
                        connectionDetails.model?.status === "Connected"
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                    }}
                  >
                    Connection Status: {connectionDetails.model?.status}
                  </Label>
                  <br />
                  <strong>View details</strong>
                </Link>
              )}
            <span>
              <Label
                style={{
                  color: "black",
                  backgroundColor:
                    exchangeStatus === "Outage detected" ? "red" : "green",
                  fontWeight: "bold",
                }}
              >
                Exchange status: {exchangeStatus}
              </Label>
            </span>
          </Grid>
        </Panel>

        <Panel header={header}>
          {isError && (
            <Message
              error
              header="There was an error trying to view this service, please try again later"
            />
          )}

          {!service && !isError && (
            <Message info header="The service could not be found" />
          )}

          {!!service && (
            <Grid columns={3}>
              {fields.map((field) => (
                <GridItem
                  field={field}
                  value={service[field.key]}
                  key={field.key}
                />
              ))}
            </Grid>
          )}
        </Panel>

        <Panel header={"Installation Address"}>
          <Grid columns={3}>
            {addressFields.map((field) => (
              <GridItem
                field={field}
                value={service[field.key]}
                key={field.key}
              />
            ))}
          </Grid>
        </Panel>
        {!permissions.isL2 && (
          <Panel header={"Connection Details"}>
            <Grid columns={3}>
              <Label>Username: {broadbandConnection?.username ?? ""}</Label>
              <Label>Password: {broadbandConnection?.password ?? ""}</Label>
              <Label>
                Router IP Address: {broadbandConnection?.routerIpAddress ?? ""}
              </Label>
            </Grid>
          </Panel>
        )}

        {<Faults reference={reference} />}
      </Segment>
      <Link to="/Services">
        <Button primary className="button-primary">
          Back
        </Button>
      </Link>
    </>
  );
}
