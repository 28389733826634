import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import LoginForm from '../components/LoginLayout';

export const authContext = React.createContext({});

export default function AuthProvider({ children }) {
  const [loadingSession, setLoadingSession] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [signInError, setSignInError] = useState('');
  const [permissions, setPermissions] = useState({});
  const history = useHistory();
  const location = useLocation();
  const { redirectTo = '/' } = queryString.parse(location.search);

  useEffect(() => {
    async function getSignInError() {
      const response = await getErrorMessage().then(message => setSignInData(message));
    }
    getSignInError();
  }, []);

  async function getErrorMessage(){
    return signInError;
  }

  function setSignInData(message){
    setSignInError(message);
    console.log(signInError);
    return message;
  }

  async function checkSessionAndRedirect() {
    try {
      const session = await Auth.currentSession();
      setUserLoggedIn(true);
      setPermissions(await getPermissions(session));

      if (location.pathname === '/login') {
        history.replace(redirectTo);
      }
    } catch (e) {
      setUserLoggedIn(false);
      setPermissions({});

      if (location.pathname !== '/login') {
        const encodedPath = encodeURIComponent(location.pathname + location.search);
        history.replace(`/login?redirectTo=${encodedPath}`);
      }
    }

    setLoadingSession(false);
  }

  async function login(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      setUserLoggedIn(true);
      setPermissions(await getPermissions());
      sessionStorage.setItem('email', email )
      setSignInError('');
      history.replace(redirectTo);
    }
    catch (error) {
      setSignInError(error.message);
      throw new Error(setSignInData(error.message));
    }
  }

  async function logout() {
    try {
      await Auth.signOut();
      setUserLoggedIn(false);
      setPermissions({});
      sessionStorage.clear();
      history.replace('/login');
    }
    catch (error) {
      console.log('error signing out', error);
    }
  }

  async function getPermissions(session) {
    try {
      session = session ?? await Auth.currentSession();
      const token = session ? session.getIdToken() : null;
      if (!session || !token) {
        return {}
      }

      return {
        admin: token.payload['custom:admin'] && token.payload['custom:admin'] === 'true' || token.payload['custom:customer'] === 'Hightide',
        orderAccess: token.payload['custom:order_access'] && token.payload['custom:order_access'] === 'true',
        servicesAccess: token.payload['custom:services_access'] && token.payload['custom:services_access'] === 'true',
        diagnosticsAccess: token.payload['custom:diagnostics_access'] && token.payload['custom:diagnostics_access'] === 'true',
        ethernetAccess: token.payload['custom:ethernet_access'] && token.payload['custom:ethernet_access'] === 'true',
        demoUser: token.payload['custom:demo_user'] && token.payload['custom:demo_user'] === 'true',
        isL2: token.payload['custom:L2'] && token.payload['custom:L2'] === 'true',
        email: token.payload['email']
      }
    }
    catch (error) {
      console.log('error getting permissions', error);
      return {};
    }
  }

  useEffect(() => {
    checkSessionAndRedirect();
    // this lint rule literally does nothing in in this case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (loadingSession) {
    return null;
  }

  const authContextValue = {
    login,
    logout,
    userLoggedIn,
    signInError,
    permissions
  };

  return <authContext.Provider value={authContextValue}>{children}</authContext.Provider>;
}
