import React, { useContext, useState } from 'react';
import Panel from '../Panel';
import { authContext } from '../../containers/AuthProvider';
import { useParams } from 'react-router-dom';
import ReactFauxDOM from 'react-faux-dom';
import { Message } from 'semantic-ui-react';
import { renderGraph, renderDay, renderYAxis } from './d3Utils';
import './RadacctGraph.css';

export default function RadacctGraph(props) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view faults" />
      </Panel>
    )
  }

  const renderGraphWrapper = () => {
    const { data } = props;
    const node = ReactFauxDOM.createElement('div', props.width, props.height);

    let { payload } = data;
    payload.sort((a, b) => a.id - b.id);

    return renderGraph(node, props.width, props.height, payload, data);
  }

  return (
    <>
      {renderGraphWrapper()}
    </>
  )
}
