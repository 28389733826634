import React, { useContext, useEffect, useState } from "react";
import Panel from "./Panel";
import {
  Button,
  Dimmer,
  Divider,
  Grid,
  GridColumn,
  Loader,
  Message,
  Segment,
  Header,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { searchOrders } from "../libs/requests/orderSearch";
import { authContext } from "../containers/AuthProvider";
import moment from "moment-timezone";
import ZenUpdatesTable from "./ZenUpdatesTable";
import GiganetUpdatesTable from "./GiganetUpdatesTable";

// TODO - TEST THAT FIELDS DISAPPEAR AND APPEAR FOR GIGANET

const dateFn = (val) => moment(val).format("DD-MM-YYYY");

const fields = [
  { key: "customerReference", label: "Customer Reference" },
  { key: "provisionType", label: "Provision Type" },
  { key: "phoneNumber", label: "CLI" },
  { key: "orderedBy", label: "Ordered By" },
  { key: "linkedOrderReference", label: "Linked Order Reference" },
  { key: "rid", label: "Reseller ID" },
  { divider: true },
  { key: "productDescription", label: "Product" },
  { key: "careLevel", label: "Care Level" },
  { key: "trafficWeighting", label: "Traffic Weighting" },
  { key: "preferredActivationDate", label: "Activation Date", format: dateFn },
  { key: "network", label: "Network" },
  { key: "upstreamSpeed", label: "Upstream Speed" },
  { divider: true },
  { key: "organisationName", label: "Organisation" },
  { key: "subPremiseName", label: "Sub Premise" },
  { key: "premiseName", label: "Premise" },
  { key: "thoroughfareNumber", label: "House Number" },
  { key: "thoroughfare", label: "Street" },
  { key: "dependentThoroughfare", label: "Dependent Thoroughfare" },
  { key: "postTown", label: "Town" },
  { key: "postCode", label: "Post Code" },
];

const progressFields = [
  { key: "status", label: "Status" },
  { key: "statusReason", label: "Status Reason" },
  { key: "serviceId", label: "Service ID" },
  { key: "accessLineId", label: "Access Line ID" },
  { key: "accessLineType", label: "Access Line Type" },
  { divider: true },
  { key: "orderDate", label: "Placed Date", format: dateFn },
  { key: "committedDate", label: "Committed Date", format: dateFn },
  { key: "completedDate", label: "Completed Date", format: dateFn },
];

const appointmentFields = [
  { divider: true },
  {
    key: "appointmentDate",
    label: "Confirmed Appointment Date",
    format: dateFn,
  },
  { key: "timeslot", label: "Confirmed Appointment Timeslot" },
  { key: "appointmentReference", label: "Appointment Reference" },
  { key: "appointmentType", label: "Appointment Type" },
  { key: "contactFullName", label: "Contact Name" },
  { key: "contactPhoneNumber", label: "Contact Phone Number" },
  { key: "siteSafetyNotes", label: "Site Safety Notes" },
  {
    key: "siteSpecialArrangementNotes",
    label: "Site Special Arrangement Notes",
  },
  { key: "siteVisitNotes", label: "Site Visit Notes" },
];

export const GridItem = ({ field, value, columns }) => {
  const { divider, label } = field;
  columns = columns || 2;

  if (divider || (value !== null && value !== undefined && value !== "")) {
    const displayValue = field.format && value ? field.format(value) : value;

    if (divider) {
      return (
        <GridColumn width={16}>
          <Divider />
        </GridColumn>
      );
    }

    return (
      <GridColumn key={label}>
        <Grid columns={2}>
          <GridColumn>
            <strong>{label}</strong>
          </GridColumn>
          <GridColumn>{displayValue}</GridColumn>
        </Grid>
      </GridColumn>
    );
  }

  return null;
};

const sectionHasData = (sectionFields, order) => {
  return sectionFields.some((field) => {
    const value = order[field.key];
    return value !== null && value !== undefined && value !== "";
  });
};

// Function to modify the "Ordered By" field
const modifyOrderFields = (order) => {
  if (order.orderedBy && order.orderedBy.toLowerCase() === 'ws-api-faelix') {
    return { ...order, orderedBy: 'ws-api-hightide' };
  }
  return order;
};

export default function ViewOrder() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState({});
  const [zenUpdates, setZenUpdates] = useState([]);
  const [giganetUpdates, setGiganetUpdates] = useState([]);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.orderAccess) {
          setIsLoading(true);
          const result = await searchOrders(reference);
          const modifiedOrder = modifyOrderFields(result.orders[0]); // Modify the order
          setOrder(modifiedOrder);
          setZenUpdates(result.zenUpdates);
          setGiganetUpdates(result.giganetUpdates);
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, [reference, permissions]);

  useEffect(() => {
    // Perform any action here. For example, log the state.
  }, [zenUpdates]); // Dependency array ensures this runs when zenUpdates changes

  useEffect(() => {
    // Perform any action here. For example, log the state.
  }, [giganetUpdates]); // Dependency array ensures this runs when giganetUpdates changes

  const hasAppointment = () => {
    if (!order) return false;
    return !!(
      order.requiresEngineerAppointment === "Yes" || order.contactFullName
    );
  };

  if (!isLoading && !permissions.orderAccess) {
    return (
      <Segment padded>
        <Message error header="You don't have permission to view this page" />
      </Segment>
    );
  }

  const header = (
    <Header
      as="h2"
      textAlign="center"
      style={{
        margin: "20px 0",
        color: "black", // Set the text color to black or white as needed
        zIndex: 1000, // Bring the text to the front
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background to ensure readability
        padding: "10px", // Optional: Add padding for better spacing
        borderRadius: "5px", // Optional: Add border radius for better aesthetics
      }}
    >
      Order Reference {reference}
      <Header.Subheader style={{ color: "black" }}>
        {order ? order.status : ""}
      </Header.Subheader>
    </Header>
  );

  let extraFields = hasAppointment()
    ? progressFields.concat(appointmentFields)
    : progressFields;

  return (
    <>
      <Segment padded>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <div style={{ marginBottom: "20px" }}>
          <Panel header={header}>
            {isError && (
              <Message
                error
                header="There was an error trying to view this order, please try again later"
              />
            )}
            {!order && !isError && (
              <Message info header="The order could not be found" />
            )}
            {!!order && sectionHasData(fields, order) && (
              <Grid columns={3}>
                {fields.map((field) => (
                  <GridItem
                    field={field}
                    value={order[field.key]}
                    key={field.key}
                  />
                ))}
              </Grid>
            )}
          </Panel>
        </div>
        {!!order && sectionHasData(extraFields, order) && (
          <Panel header={"Order Progress"}>
            <Grid columns={3}>
              {extraFields.map((field) => (
                <GridItem
                  field={field}
                  value={order[field.key]}
                  key={field.key}
                />
              ))}
            </Grid>
          </Panel>
        )}
        {Object.keys(zenUpdates).length > 0 && (
          <Panel header={"Order Updates"}>
            <ZenUpdatesTable updates={zenUpdates} />
          </Panel>
        )}

        {Object.keys(giganetUpdates).length > 0 && (
          <Panel header={"Order Updates"}>
            <GiganetUpdatesTable updates={giganetUpdates} />
          </Panel>
        )}
      </Segment>
      <Link to="/SearchOrders">
        <Button
          primary
          className="button-primary"
          style={{ marginTop: "20px" }}
        >
          Back
        </Button>
      </Link>
    </>
  );
}
