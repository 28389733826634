import React, { useState, useContext } from "react";
import Panel from "../Panel";
import { useParams } from "react-router-dom";
import { authContext } from "../../containers/AuthProvider";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Message,
} from "semantic-ui-react";
import { getDiagnostics } from "../../libs/requests/getDiagnostics";
import { themeContext } from "../../containers/ThemeProvider";

const faultTypes = ["Synchronisation", "Authentication", "Performance"];
const faultSeverity = ["Permanent", "Intermittent"];

const diagnosticChecks = {
  Synchronisation: [
    { label: "Test socket", value: 1 },
    { label: "Alternative router", value: 2 },
    { label: "Alternative filler", value: 3 },
    { label: "Alternative RJ11 cable", value: 4 },
    { label: "Tested with only router connected", value: 5 },
    { label: "Quiet line test", value: 6 },
    { label: "Line has correct DN", value: 7 },
  ],
  Authentication: [
    { label: "Valid username and password", value: 1 },
    { label: "Valid realm", value: 2 },
    { label: "Alternative router", value: 3 },
    { label: "Test usernames tried", value: 4 },
    { label: "Correct VCI/VPI (0, 38) or VLAN tag", value: 5 },
    { label: "Correct encapsulation (PPPoE or PPPoA)", value: 6 },
  ],
  Performance: [
    { label: "Low sync speeds", value: 1 },
    { label: "Profile problem", value: 2 },
    { label: "Packet loss", value: 3 },
    { label: "Tested outside VPN", value: 4 },
    { label: "Performance test (Wired)", value: 5 },
    { label: "Usage and saturation ruled out", value: 6 },
    { label: "Traceroute/PathPing/MTR to router", value: 7 },
    { label: "Valid realm", value: 8 },
  ],
};

const appointmentPreferences = [
  { label: "First available", value: 1 },
  { label: "AM preferred", value: 2 },
  { label: "PM preferred", value: 3 },
];

export default function RaiseFault({ onCancel, testId }) {
  const { reference } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [faultType, setFaultType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [description, setDescription] = useState("");
  const [checks, setChecks] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [appointmentPref, setAppointmentPref] = useState(1);
  const [acceptCharges, setAcceptCharges] = useState(false);
  const [intermittencyPattern, setIntermittencyPattern] = useState("");
  const [occurrencesPerDay, setOccurrencesPerDay] = useState("");
  const [message, setMessage] = useState("");
  const [appointmentDescription, setAppointmentDescription] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [speedTestResults, setSpeedtestResults] = useState("");
  

  const { assets } = useContext(themeContext);
  const { permissions } = useContext(authContext);
  const ukPhoneRegex = /^(?:\+44|0)(?:7\d{9}|1\d{8,9}|2\d{8,9}|3\d{8,9}|5\d{8,9}|8\d{8,9})$/;

  const getSelectedEnumValues = (selectedLabels, faultType) => {
    return diagnosticChecks[faultType]
      ?.filter((check) => selectedLabels?.includes(check.label))
      ?.map((check) => check?.value);
  };

  const getFaultTypeEnum = (faultType, severity) => {
    const faultMapping = {
      Synchronisation: {
        Permanent: "SyncPerm",
        Intermittent: "SyncInterm",
      },
      Authentication: {
        Permanent: "AuthPerm",
        Intermittent: "AuthInterm",
      },
      Performance: {
        Permanent: "PerformPerm",
        Intermittent: "PerformInterm",
      },
    };
  
    return faultMapping[faultType]?.[severity] || null;
  };

  const handleSubmit = async () => {

    if (!testId) {
      setMessage("You must run a diagnostic test before raising a fault.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
    
    if (!acceptCharges) {
      setMessage("You must accept charges before raising a fault.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    if (!description || !contactName || !contactPhone || !appointmentDescription) {
      setMessage("Please fill in all mandatory fields.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    if (!ukPhoneRegex.test(contactPhone)) {
      setMessage("Please enter a valid UK mobile or landline number.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    if (severity === "Intermittent" && (!occurrencesPerDay || isNaN(occurrencesPerDay))) {
      setMessage("Occurrences per day must be a valid number.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    if (faultType === "Authentication" && (!username || !password)) {
      setMessage("Please fill in all mandatory fields.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    if (faultType === "Performance" && !speedTestResults) {
      setMessage("Please fill in all mandatory fields.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }

    if (!checks || checks.length === 0) {
      setMessage("Please select at least one diagnostic check.");
      setIsError(true);
      setIsSuccess(false);
      return;
    }
  
    const selectedEnumValues = getSelectedEnumValues(checks, faultType);
    const faultTypeEnum = getFaultTypeEnum(faultType, severity);

    let requestBody = {
      faultDetails: {
        description,
        diagnosticChecks: checks?.length > 0 ? selectedEnumValues : [],
      },
      diagnosticTestResults: { testId },
      service: { zenReference: reference },
      contact: contactName
        ? { email: permissions?.email }
        : undefined,
      terms: { acceptCharges },
      engineerAppointment:
        contactName || contactPhone
          ? {
              onSiteContactName: contactName,
              onSiteContactPhoneNumber: contactPhone,
              preferredAppointment: {
                appointmentPreference: appointmentPref,
                additionalAppointmentDetails: appointmentDescription,
              },
            }
          : undefined,
    };

    if (faultType === "Authentication") {
      requestBody.faultDetails.routerConfiguration = {
        username: username,
        password: password,
      };

      if (severity === "Intermittent") {
        requestBody.faultDetails.intermittencyDetails = {
          intermittencyPattern,
          occurrencesPerDay,
        };
      }
    }

    if (faultType === "Performance") {
      requestBody.faultDetails.speedTestResults = speedTestResults;
    }

    Object.keys(requestBody)?.forEach((key) => {
      if (requestBody[key] === undefined) delete requestBody[key];
    });

    setIsLoading(true);
    try {
      const result = await getDiagnostics(
        reference,
        [faultTypeEnum],
        "POST",
        requestBody
      );

      debugger;

      if (
        result?.syncPerm === "OK" ||
        result?.syncInterm === "OK" ||
        result?.authPerm === "OK" ||
        result?.authInterm === "OK" ||
        result?.performPerm === "OK" ||
        result?.performInterm === "OK"
       
      ) {
        setMessage("Fault raised successfully!");
        setIsSuccess(true);
        setIsError(false);
      } else {
        setMessage("Failed to raise fault, please try again.");
        setIsError(true);
      }
    } catch (error) {
      console.error(error);
      setMessage("Failed to raise fault, please try again.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Panel header="Raise Fault">
      <Form>
        <Grid className="raise-fault">
          <Grid.Row>
            <Grid.Column width={4}>
              <strong>Fault Type</strong>
            </Grid.Column>
            <Grid.Column width={12}>
              {faultTypes?.map((type) => (
                <Button
                  key={type}
                  basic
                  color={faultType === type ? "teal" : "black"}
                  onClick={() => setFaultType(type)}
                  required
                >
                  {type}
                </Button>
              ))}
            </Grid.Column>
          </Grid.Row>
          {faultType && (
            <>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Description</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.TextArea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    data-testid="description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Severity</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  {faultSeverity?.map((level) => (
                    <Button
                      key={level}
                      basic
                      color={severity === level ? "teal" : "black"}
                      onClick={() => setSeverity(level)}
                    >
                      {level}
                    </Button>
                  ))}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Diagnostic Checks</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  {diagnosticChecks[faultType]?.map((check) => (
                    <Checkbox
                      data-testid="diagnosticCheck"
                      key={check?.value}
                      label={check?.label}
                      onChange={(e, { checked }) => {
                        setChecks(
                          checked
                            ? [...checks, check?.label]
                            : checks?.filter((c) => c !== check?.label)
                        );
                      }}
                    />
                  ))}
                </Grid.Column>
              </Grid.Row>
              {faultType === "Performance" && (
                <>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <strong>
                      Please provide any speed test results, references or
                      relevant performance data which will help to progress
                      fault investigations
                    </strong>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Form.TextArea
                      value={speedTestResults}
                      onChange={(e) => setSpeedtestResults(e.target.value)}
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                </>
              )}
              {severity === "Intermittent" && (
                <>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <strong>Intermittency Pattern</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form.TextArea
                        required
                        value={intermittencyPattern}
                        onChange={(e) =>
                          setIntermittencyPattern(e.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <strong>Occurrences Per Day</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Input
                        required
                        value={occurrencesPerDay}
                        onChange={(e) => setOccurrencesPerDay(e.target.value)}
                        data-testid="occurrencesPerDay"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              {faultType === "Authentication" && (
                <>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <strong>Username</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Input
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        data-testid="username"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <strong>Password</strong>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Input 
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        data-testid="password"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>On-site Contact Name</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Input
                    required
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    data-testid="contactName"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>On-site Contact Phone</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Input
                    required
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                    data-testid="contactPhone"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Appointment Preference</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  {appointmentPreferences?.map((pref) => (
                    <Button
                      key={pref?.value}
                      basic
                      color={appointmentPref === pref?.value ? "teal" : "black"}
                      onClick={() => setAppointmentPref(pref?.value)}
                      data-testid="appointmentPref"
                    >
                      {pref?.label}
                    </Button>
                  ))}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Additional Appointment Details</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.TextArea
                    required
                    value={appointmentDescription}
                    onChange={(e) => setAppointmentDescription(e.target.value)}
                    data-testid="appointmentDescription"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>Accept Charges?</strong>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Checkbox
                    required
                    label="I accept charges"
                    checked={acceptCharges}
                    onChange={() => setAcceptCharges(!acceptCharges)}
                    data-testid="acceptCharges"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button
                    primary
                    onClick={handleSubmit}
                    loading={isLoading}
                    style={{ backgroundColor: assets?.headerColour }}
                  >
                    Submit Fault
                  </Button>

                  <Button secondary onClick={onCancel}>
                    Cancel
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Form>
      {message && (
        <Message
          success={isSuccess}
          error={isError}
          header={isSuccess ? "Success!" : "Error"}
          content={message}
        />
      )}
    </Panel>
  );
}
