import React, { useContext, useState, useEffect } from "react";
import { authContext } from "./AuthProvider";
import { NavLink } from "react-router-dom";
import { themeContext } from "./ThemeProvider";
import { getSupplier } from "../libs/sessionVariable";
import OrderNotifications from '../components/Notifications/OrderNotifications.jsx'; 
import SearchOrders from "../components/SearchOrders.jsx";

import "./LandingPage.css";

export default function LandingPage() {
  const { logout, userLoggedIn, permissions } = useContext(authContext);
  const { assets } = useContext(themeContext);
  const [supplier, setSupplier] = useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const [showOrders, setShowOrders] = useState(false);

  useEffect(() => {
    if (userLoggedIn) {
      async function fetchSupplier() {
        const defaultSupplier = await getSupplier();
        setSupplier(defaultSupplier);
      }
      fetchSupplier();
    }
  }, [userLoggedIn]);

  const handleNotificationsClick = () => {
    setShowNotifications(true);
    setShowOrders(false);
  };

  const handleOrdersClick = () => {
    setShowOrders(true);
    setShowNotifications(false);
  };

  return (
    <div 
      className="landing-page__container" 
      style={{ backgroundColor: assets.backgroundColour || "#FFFFFF" }}
    >
      {/* Header showcasing the app */}
      <header className="landing-page__header">
        <img
          className="landing-page__logo"
          src={assets.logo}
          alt="App Logo"
          style={assets.logoNavStyle}
        />
        <h1 style={{ color: assets.headerColour }}>Order Management</h1>
      </header>

      {/* Main demo content */}
      <div className="landing-page__content">
        <section className="landing-page__section">
          <div className="landing-page__nav-items" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {permissions.orderAccess && (
              <NavLink className="landing-page__nav-link" to="#" activeClassName="activated" onClick={handleOrdersClick} style={{ backgroundColor: assets.headerColour, color: "white" }} >
                Orders
              </NavLink>
            )}
              <NavLink className="landing-page__nav-link" to="#" activeClassName="activated" onClick={handleNotificationsClick} style={{ backgroundColor: assets.headerColour, color: "white" }}  >
                Notifications
              </NavLink>
          </div>
        </section>
        {showOrders && <SearchOrders />}
        {showNotifications && <OrderNotifications />}
      </div>
    </div>
  );
}
