import React, { useContext, useEffect, useState } from 'react';

export const themeContext = React.createContext({});

export const Fibre1 = 'Fibre1';
export const TechSmart = 'TechSmart';
export const FirstCom = 'FirstcomEurope';
export const Hightide = 'Hightide';
export const Nimans = 'Nimans';

const hostMap = {
  'portal.techsmartltd.co.uk': TechSmart,
  'portal.fibre1.uk': Fibre1,
  'portal.firstcomeurope.co.uk': FirstCom,
  'localhost:3000': Nimans,
  'connectivity.hightidegroup.net': Hightide,
  'demo.techsmart.co.uk': Nimans,
  'marstons.techsmart.co.uk': TechSmart,
};

const assetMap = {
  [Fibre1]: {
    rootClass: 'fibre1',
    logo: 'fibre1_logo.png',
    logoSize: 'small',
    headerColour: 'teal',
    backgroundColour: '#E6F2F2',
    ethernet: {
      quoteLogo: 'fibre1_logo.png',
      showSupplier: false,
      mergeTables: true
    },
  },
  [TechSmart]: {
    rootClass: 'techsmart',
    logo: 'techsmart_logo_small.png',
    logoSize: 'medium',
    logoNavStyle: { 'maxWidth': '250px' },
    logoLoginStyle: { 'marginLeft': '12%' },
    headerColour: 'blue',
    backgroundColour: '#E0ECFF',
    ethernet: {
      quoteLogo: 'techsmart_logo_small.png',
      showSupplier: false,
      mergeTables: true
    },
  },
  [Nimans]: {
    rootClass: 'nimans',
    headerColour: '#96C11F',
    backgroundColour: '#F0F8E0',
    loginLogo: 'NIMANSConnect300.png',
    logo: 'NIMANSReversed300.png',
    logoSize: 'medium',
    logoNavStyle: { 'maxWidth': '250px'},
    ethernet: {
      quoteLogo: 'NIMANSConnect300.png',
      showSupplier: true,
      mergeTables: true
    },
  }, 
  [Hightide]: {
    rootClass: 'hightide',
    loginLogo: 'high-tide-logo-photoshop.jpg',
    logo: 'high-tide-logo-photoshop.jpg',
    logoSize: 'medium',
    backgroundColour: '#F0E6F5',
    logoNavStyle: { 'maxWidth': '250px' },
    ethernet: {
      quoteLogo: 'high-tide-logo-photoshop.jpg',
      showSupplier: false,
      mergeTables: true
    },
  }
}

export default function ThemeProvider({ children }) {
  const [theme, setTheme] = useState();
  const [assets, setAssets] = useState({});

  useEffect(() => {
    const { host } = window.location;
    const hostTheme = !!hostMap[host] ? hostMap[host] : Fibre1;

    setTheme(hostTheme);
    setAssets(assetMap[hostTheme]);
  }, [])

  const themeContextValue = {
    theme,
    assets
  };

  return <themeContext.Provider value={themeContextValue}>{children}</themeContext.Provider>;
}

export const useThemeContext = () => useContext(themeContext);
