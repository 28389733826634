import React, { useEffect, useState, Component } from 'react';
import Panel from '../Panel';
import { Form, Radio, Segment, FormInput, Grid, GridColumn, GridRow, FormGroup, Divider, Dropdown, Button, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useFormik, withFormik, useField } from 'formik';
import '../OrderComponents/ZenMigration.css'
import addressSearchRequest from '../../libs/requests/addressSearch';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format, compareAsc } from 'date-fns'
import postZenOrder from '../../libs/requests/postZenOrder';
import { ActivationDate } from './ActivationDate';
import { selectErrorBannerMessage } from '../../libs/zenErrorBannerMessage';
import {getSupplier} from '../../libs/sessionVariable';
import { getRealms } from '../../libs/requests/getPartnerDetails';
import { checkUsernameValid } from '../../libs/utils';
import * as utilities from './Utilities'

export default function ZenStandardOrder() {

  const selectedProduct = sessionStorage.getItem('selectedProduct');
  const product = JSON.parse(selectedProduct);

  const selectedPhone = sessionStorage.getItem('customerDetails');
  const requestPhone = JSON.parse(selectedPhone);

  const postCode = { 'postCode': !product?.orderDetails?.lineCharacteristics?.btOpenreachPostCode ? product?.orderDetails?.lineCharacteristics?.btWholesalePostCode : product?.orderDetails?.lineCharacteristics?.btOpenreachPostCode }
  const [radioButtons, setRadioButtons] = useState('0');
  const [addressResults, setAddressResults] = useState([]);
  const todaysDate = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState({ careLevelField: "Standard" });
  const [weighting, setTrafficWeighting] = useState({ trafficWeightingField: "Standard" });
  const [zenReference, setZenReference] = useState();
  const [errorMessage, setErrorMessage] =  useState([]);
  const [addressValue, setAddressValue] = useState({ addressField: { addressReferenceNumber: '', districtCode: '', qualifier: '' } });
  const [usernameMessage, setUsernameMessageResults] = useState("");
  const [supplier, setSupplier] = useState('');
  const [realm, setRealm] = useState('dslnet');
  const [realms, setRealms] = useState(['dslnet']);
  const [isL2Customer, setIsL2Customer] = useState(false);

  useEffect(() => {
    async function fetchSuppllier(){
      const defaultSupplier = await getSupplier('Zen');
      setSupplier(defaultSupplier);
    }
    fetchSuppllier();
  }, 
  )

  useEffect(() => {
    (async () => {

      const l2 = await utilities.isL2();
      
      if(l2){
         setIsL2Customer(l2);
      }

      if(!l2){
        const realms = await getRealms();
        setRealms(realms);
        setRealm(realms[0]?.value);
      }
    })();
  }, [])

  const careLevels = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 },
    { key: 1, value: 'Enhanced', text: 'Enhanced', flag: 1 },
    { key: 2, value: 'Critical', text: 'Critical', flag: 2 },
  ];

  const trafficWeighting = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 }
  ]
  
  const ips = [
    { key: 1, value: 1, text: '1', flag: 1 },
    { key: 4, value: 4, text: '4', flag: 4 }
  ]

  const addressSearch = async (postCode) => {
    try {
      return await addressSearchRequest(postCode);
    } catch (err) {
    }
  }

  const getAddresses = async (postCode) => {
    const result = await addressSearch(postCode)
    const optionsList = [];
    result.forEach(function (element) {
      optionsList.push({ key: element.addressReference, value: element.addressReference, text: element.address, flag: element.addressReference })
    })
    return optionsList;
  }

  useEffect(() => {
    async function getAddressResults() {
      const response = await getAddresses(postCode.postCode).then(data => setAddressResults(data));
    }
    getAddressResults();
  }, []);

  const handleAddressChange = (event, result) => {
    const { name, value } = result || event.target;
    setAddressValue({ ...addressValue, [name]: value });
    setFieldValue('addressField', value);
  };

  const handleCareLevelChange = (event, result) => {
    const { name, value } = result || event.target;
    setLevel({ ...level, [name]: value });
  };

  const handleTrafficWeightingChange = (event, result) => {
    const { name, value } = result || event.target;
    setTrafficWeighting({ ...weighting, [name]: value });
  };

  const handleRealmChange = async (event, result) => {
    const { value } = result || event.target;
    setRealm(value);

    setUsernameMessageResults(await checkUsernameValid(values.usernameField, value));
  }

  const handleUsernameChange = async (e, result) => {
    setFieldValue('usernameField', e.currentTarget.value);

    setUsernameMessageResults(await checkUsernameValid(e.currentTarget.value, realm));
  };

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [startDate, setStartDate] = useState(new Date(tomorrow));

  const handleStartDateChange = (e, date) => {
    setStartDate(e);
    setFieldValue('activationDateField', e);
  };

  useEffect(() => {
    function getDatePicker() {
      const response = <DatePicker selected={format(startDate, 'dd.MM.yyyy')} onChange={(date) => setStartDate(format(date, 'dd.MM.yyyy'))} />
    }
    getDatePicker();
  }, []);

  const handleRadioChange = (e) => {
    setRadioButtons(e.currentTarget.value);
    setFieldValue('noOfIps', e.currentTarget.value);
  };

  const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, ...formik } = useFormik({
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      siteInformation: '',
      addressField: { addressReferenceNumber: '', districtCode: '', qualifier: '' },
      noOfIps: '1',
      activationDateField: '',
      orderRef: '',
      contactName: '',
      usernameField: '',
      realm: 'dslnet',
      password: '',
      rid: ''
    }
  });

  function validate({ usernameField, password, addressField, noOfIps, activationDateField, orderRef, rid }) {
    const errors = {};
    const address = addressField.qualifier === "Silver" ? addressField.uprn : addressField.addressReferenceNumber;

    if (typeof rid == "undefined"  || !rid.length ) {
      errors.rid = true;
    }
    
    if (!address) {
      errors.addressField = true;
    }

    if (!noOfIps.length && !isL2Customer) {
      errors.noOfIps = true;
    }

    if (!orderRef.length) {
      errors.orderRef = true;
    }

    if (!usernameField && !isL2Customer) {
      errors.username = true;
    }

    if (!password.length && !isL2Customer) {
      errors.password = true;
    }

    return errors;
  }

  async function onSubmit({ usernameField, password, addressField, noOfIps, activationDateField, orderRef, contactName, rid }) {
    setIsLoading(true);

    const selectedAddress = addressField?.qualifier === "Gold" ? addressResults.find(element => element.key.addressReferenceNumber === addressField?.addressReferenceNumber)
      : addressResults.find(element => element.key.uprn === addressField?.uprn);

    const addressDetails = selectedAddress.text;
    const address = addressDetails.split(',');

    const isPremiseNameOrThoroughfare = parseInt(address[0]);
    const usePremiseName = isNaN(isPremiseNameOrThoroughfare);
    const premiseName =  usePremiseName ? address[0] : null;
    const thoroughFareNumber = !usePremiseName ? address[0] : null;
    let activationDate = !document.getElementById("activationDateField") ? "" : document.getElementById("activationDateField").defaultValue;
    let year = activationDate.substring(6, 10);
    let month = activationDate.substring(3, 5) - 1; // JavaScript months are 0-indexed
    let day = activationDate.substring(0, 2);
    let dateObject = new Date(year, month, day, 12, 0, 0);
    activationDate = dateObject.toISOString();
    
    try {

      const javaObject = {
        availabilityReference: product.orderDetails.availabilityReference,
        installationDetails: {
          phoneNumber: product.orderDetails && product.orderDetails.accessLines ? product.orderDetails.accessLines[0].lineNumber : requestPhone.phoneNumber,
          goldAddressKey: typeof addressField?.addressReferenceNumber != "undefined" ? addressField?.addressReferenceNumber : "",
          districtCode: addressField.districtCode,
          address: {
            premiseName: premiseName,
            thoroughfareNumber: thoroughFareNumber,
            thoroughfare: address[1],
            postTown: address[2].trim(),
            postCode: postCode.postCode.trim()
          },
          uprn: typeof addressField?.uprn != "undefined" ? addressField?.uprn : ""
        },
        productCode: product.productCode,
        options: [
          {
            type: 1,
            value: level.careLevelField
          },
          {
            type: 2,
            value: weighting.trafficWeightingField
          }
        ],
        preferredActivationDate: activationDate,
        orderReference: orderRef,
        rid: rid,
        ...(!isL2Customer ? { 
        broadbandCredentials: {
          username: `${usernameField}@${realm}`,
          password: password,
          numberOfIps: parseInt(noOfIps)
        }
      } : {}),
        accessLineId: product.orderDetails && product.orderDetails.accessLines ? product.orderDetails.accessLines[0].lineNumber : requestPhone.phoneNumber
      };
      
      const body = JSON.stringify(javaObject);
      setErrorMessage([]);
      setZenReference(await postZenOrder(body));
      setErrorMessage([]);
      setIsLoading(false);

    } catch (ex) {
      setErrorMessage([]);
      const errors = selectErrorBannerMessage(ex);
      setErrorMessage(errors);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Panel header={supplier + ' ' + product.productName}>
      {errorMessage.length !== 0 && <Message error header='There was some errors with your submission' list={
        errorMessage
         }
        />}

      {zenReference && <Message success header='Your submission was successful'  content={'Order reference: ' + zenReference}
      />}
      <Form onSubmit={useFormik.handleSubmit} className='disabled'>
          <Grid columns={1}>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width: "250px" }}
                    inline
                    label='Line Cli'
                    name='lineCLI'
                    id='lineCLI'
                    data-testid='lineCLI'
                    disabled={true}
                    value={product.orderDetails && product.orderDetails.accessLines ? product.orderDetails.accessLines[0].lineNumber : requestPhone.phoneNumber}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width: "250px" }}
                    inline
                    label='Product'
                    name='productLabel'
                    data-testid='productCode'
                    disabled={true}
                    value={product.productCode}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width: "250px" }}
                    inline
                    label='Provision Type'
                    name='provisionLabel'
                    data-testid='provisioningType'
                    disabled={true}
                    value={product?.orderDetails?.provision?.provisionType == 2 ? 'Migration' : 'New Provide'}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    name='addressLabel'
                    data-testid='addressDropDown'
                    label={'Address  - '.concat(postCode.postCode)}
                    disabled={true}
                    required
                  >
                  </Form.Field>
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    placeholder='Select Address'
                    fluid
                    search
                    selection
                    options={addressResults || []}
                    label='addressField'
                    id="addressField"
                    name="addressField"
                    onChange={(e, val) => handleAddressChange(e, val, setFieldValue)}
                    value={addressValue.addressField}
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    label={'Product Options'}
                    name='optionsLabel'
                    data-testid='optionsLabel'
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field
                    label="Care Level"
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            placeholder='Select Care Level'
                            fluid
                            search
                            selection
                            options={careLevels}
                            label='careLevelField'
                            id='careLevelField'
                            name='careLevelField'
                            defaultValue={careLevels[0].value}
                            onChange={handleCareLevelChange}
                            value={level.careLevelField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <FormGroup>
                  <Form.Field
                    label="Traffic Weighting"
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            placeholder='Select Traffic Weighting'
                            fluid
                            search
                            selection
                            options={trafficWeighting}
                            label='trafficWeightingField'
                            id='trafficWeightingField'
                            name='trafficWeightingField'
                            defaultValue={trafficWeighting[0].value}
                            onChange={handleTrafficWeightingChange}
                            value={weighting.trafficWeightingField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {!isL2Customer && (
                  <>
                <Divider></Divider>
                <FormGroup>
                  <Form.Field
                    style={{ width: '250px' }}
                    inline
                    label='Credentials'
                    name='credentials'
                    data-testid='credentialsLabel'
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    style={{ width: '250px' }}
                    inline
                    label='Username'
                    name='usernameField'
                    data-testid='usernameField'
                    disabled={false}
                    onChange={handleUsernameChange}
                    values={values.usernameField}
                    required
                  />
                  <Dropdown
                    style={{ height: '38px', padding: '7px', minWidth: '200px' }}
                    options={realms}
                    label='realmField'
                    id='realmField'
                    name='realmField'
                    defaultValue={realm}
                    onChange={handleRealmChange}
                    value={realm}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    style={{ width: "250px" }}
                    inline
                    label='Password'
                    name='password'
                    id='password'
                    data-testid='password'
                    value={values.password}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                {(
                  usernameMessage.includes('The username should be a minimum of 3 characters') && <span className='login__error'>{usernameMessage}</span>
                )}
                {(
                  (usernameMessage.includes('Available') || usernameMessage.includes('NotAvailable') || usernameMessage.includes('Invalid')) && <span className='login__success'>{usernameMessage}</span>
                )}
                </>
                )}
                {!isL2Customer && (
                  <>
                <Divider></Divider>
                <FormGroup>
                  <Form.Field
                    inline
                    label='No of IP Addresses'
                    name='ipsLabel'
                    data-testid='ipsLabel'
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field>
                    <input type="radio"
                      name="noOfIps"
                      value={ips[0].value}
                      defaultChecked
                      className='ui.radio'
                      onChange={(e) => handleRadioChange(e, setFieldValue)}
                    />
                  </Form.Field>
                  <label>1</label>
                  <Form.Field>
                    <input type="radio"
                      name="noOfIps"
                      value={ips[1].value}
                      className='ui.radio'
                      onChange={(e, val) => handleRadioChange(e, val, setFieldValue)}
                    />
                  </Form.Field>
                  <label>4</label>
                </FormGroup>
                </>
                )}
                <Divider></Divider>
                {<ActivationDate show={true} today={new Date()} leadTimeDays={product?.orderDetails?.provision?.provisionType === 2 ? 9 : 5} />}
                <FormGroup>
                  <Form.Field
                    inline
                    label='References'
                    name='references'
                    data-testid='referencesLabel'
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label='Order Reference'
                    name='orderRef'
                    data-testid='orderRefLabel'
                    disabled={false}
                    value={values.orderRef}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label='RID'
                    name='rid'
                    data-testid='ridLabel'
                    disabled={false}
                    value={values.rid}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <Button
                  disabled={!isValid || zenReference}
                  className='button-primary'
                  floated='right'
                  content='Place Order'
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </Panel>
    </>
  )
}